import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { RfhLogo } from '@rfh-core/illustrations'

import content from '../content'

export const Appbar = () => {
  const { appbar } = content

  return (
    <Box
      bgcolor='white'
      sx={{
        zIndex: ({ zIndex }) => zIndex.drawer + 1,
        width: '100%',
        position: 'inherit',
      }}
    >
      <AppBar>
        <Container>
          <Toolbar>
            <a href='#'>
              <RfhLogo
                sx={{
                  width: 'auto',
                  height: '44px',
                  color: ({ rfhColors }) => rfhColors.black,
                  display: 'block',
                  marginRight: ({ spacing }) => spacing(4),
                }}
              />
            </a>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: '1',
                alignItems: 'flex-start',
              }}
            >
              <Typography component='h1' noWrap variant='body2'>
                {appbar}
              </Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}
