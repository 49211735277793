const content = {
  title: 'OFLM',
  appbar: ' OFLM - Dashboard logistieke middelen',
  alerts: {
    error: 'Uw verzoek kon niet worden verwerkt.',
  },
  logout: 'Uitloggen',
  dashboard: {
    title: 'Dashboard',
    buttons: {
      search: 'Zoeken',
      delete: 'Wissen',
    },
    filters: {
      dateSelection: 'Veildatum',
      day: 'Dag',
      week: 'Week',
      logistic: 'Logistieke middelen',
      stream: 'Stroom',
      location: 'Locatie',
    },
    informationFields: {
      input: 'Ingeslagen',
      delivered: 'Afgeleverd',
      deliveryDepot: 'ingeleverd bij depot door RFH',
      difference: 'Verschil',
      differenceExplained: 'Afgeleverd + Ingeleverd - Ingeslagen',
      info: 'De kleuren van deze velden refereren naar de kleuren in de grafiek.',
    },
    barchart: {
      noData:
        'Geen data beschikbaar om een grafiek te tonen, pas het filter aan.',
      amount: 'Aantal statiegeldlegborden',
      difference: 'Verschil',
    },
  },
}

export default content
