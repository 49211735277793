import {
  AnySearchSchema,
  ParsedLocation,
  redirect,
} from '@tanstack/react-router'

import { RouterContext } from '../types/RouterContext'

export function ensureAuthenticated({
  context,
  location,
}: {
  context: RouterContext
  location: ParsedLocation<AnySearchSchema>
}) {
  if (!context.auth.authState || !context.auth.authState.isAuthenticated) {
    throw redirect({
      to: '/login',
      search: {
        redirect: location.href,
      },
    })
  }
}
