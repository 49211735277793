import { Typography, Box } from '@mui/material'
import { RfhThemeOptions } from '@rfh-core/theme'

type DashboardColoredTextfieldProps = {
  title: string
  color: keyof RfhThemeOptions['rfhColors']
  amount: number | undefined
  explanation?: string
}

export const DashboardColoredTextfield = ({
  title,
  color,
  amount,
  explanation,
}: DashboardColoredTextfieldProps) => (
  <div style={{ paddingRight: '50px', paddingBottom: 20, paddingTop: 15 }}>
    <Typography variant='h5' sx={{ mb: 1 }} display='flex'>
      {title}
    </Typography>
    <Box
      sx={{ bgcolor: ({ rfhColors }) => rfhColors[color] }}
      width='180px'
      height='40px'
      display='flex'
      alignItems='center'
      pl={2}
      border='1px solid lightgray'
    >
      <Typography
        variant='h5'
        className='book'
        sx={{ color: ({ rfhColors }) => rfhColors.grey }}
      >
        {amount}
      </Typography>
    </Box>
    <Typography variant='h6' className='book' sx={{ ml: 1 }}>
      {explanation}
    </Typography>
  </div>
)
