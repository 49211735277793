import OktaAuth from '@okta/okta-auth-js'

import { Config } from '../Config'

type Options = {
  oktaAuth: OktaAuth
} & RequestInit

export const authenticatedFetch = async (url: string, options: Options) => {
  const { oktaAuth, ...rest } = options
  const { accessToken } = await oktaAuth.tokenManager.getTokens()

  const fullUrl = `${Config.api.host}/${Config.api.path}/${Config.api.version}${url}`

  return fetch(fullUrl, {
    ...rest,
    headers: {
      Authorization: `Bearer ${accessToken?.accessToken}`,
      ...rest.headers,
    },
  })
}
