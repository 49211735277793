import { setDefaultOptions } from 'date-fns'
import { nl } from 'date-fns/locale/nl'

import React, { Suspense } from 'react'

import { Box, Container } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Footer } from '@rfh-core/components'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'

import { Appbar } from '../components/Appbar'
import {
  FilterDrawerContextProvider,
  FilterDrawerContainer,
} from '../components/FilterDrawer'
import { Loader } from '../components/Loader'
import { RouterContext } from '../types/RouterContext'

setDefaultOptions({ weekStartsOn: 1 })

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : React.lazy(() =>
        import('@tanstack/router-devtools').then(result => ({
          default: result.TanStackRouterDevtools,
        }))
      )

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <FilterDrawerContextProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
        <Loader />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Appbar />
          <FilterDrawerContainer
            enabled={true}
            minHeight={'calc(100vh - 54px)'}
          >
            <Container maxWidth='xl' sx={{ flexGrow: 1 }}>
              <Outlet />
            </Container>
            <Footer />
          </FilterDrawerContainer>
        </Box>
        <ReactQueryDevtools />
        <Suspense>
          <TanStackRouterDevtools />
        </Suspense>
      </LocalizationProvider>
    </FilterDrawerContextProvider>
  ),
})
