import { z } from 'zod'

import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/login')({
  validateSearch: z.object({
    redirect: z.string().catch('/'),
  }),
  loaderDeps: ({ search }) => ({
    redirect: search.redirect,
  }),
  loader: async ({ context: { auth }, deps: { redirect } }) => {
    if (!auth.authState?.isAuthenticated) {
      await auth.oktaAuth.signInWithRedirect({ originalUri: redirect })
    }
  },
})
