import {
  endOfWeek,
  format,
  parseISO,
  startOfWeek,
  isWithinInterval,
  formatISO,
} from 'date-fns'
import { nl } from 'date-fns/locale'
import { z } from 'zod'

import { Typography, Stack } from '@mui/material'
import { queryOptions } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'

import { BarChart } from '../components/dashboard/Barchart'
import { DashboardColoredTextfield } from '../components/dashboard/DashboardColoredTextfield'
import { SearchFormDashboard } from '../components/dashboard/SearchFormDashboard'
import content from '../content'
import { getSelectedLocation } from '../services/selectedLocation'
import { DashboardTypes } from '../types/Dashboard'
import { authenticatedFetch } from '../utils/authenticatedFetch'
import { ensureAuthenticated } from '../utils/ensureAuthenticated'
import { getFullWeek } from '../utils/getDates'

export const Route = createFileRoute('/')({
  component: () => <Dashboard />,
  validateSearch: z.object({
    date: z.string().optional(),
    mode: z.enum(['day', 'week']).optional(),
    location: z.string().optional(),
  }),
  loaderDeps: ({ search }) => ({
    date: search.date ?? format(new Date(), 'yyyy-MM-dd'),
    mode: search.mode ?? 'day',
    location: search.location ?? getSelectedLocation() ?? 'aalsmeer',
  }),
  beforeLoad: ensureAuthenticated,
  loader: ({ context, deps: { date, location } }) =>
    context.queryClient.fetchQuery(
      queryOptions({
        queryKey: ['getDashboardDetails', date, location],
        queryFn: async () => {
          const inputDate = parseISO(date)
          const start = startOfWeek(inputDate, { weekStartsOn: 1 })
          const end = endOfWeek(inputDate, { weekStartsOn: 1 })

          const response = await authenticatedFetch('/LocationDashboard', {
            oktaAuth: context.auth.oktaAuth,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              location,
              startDate: formatISO(start, { representation: 'date' }),
              endDate: formatISO(end, { representation: 'date' }),
              stroom: 'Klok',
              logistiekMiddel: 'Statiegeldlegbord',
            }),
          })

          return response
            .json()
            .then(json => z.array(DashboardTypes).parse(json))
        },
        staleTime: 10,
      })
    ),
})
const sum = (a: number, b: number) => a + b

const calculateVerschil = ({
  afgeleverd,
  ingeleverdBijDepot,
  ingeslagen,
}: DashboardTypes) => afgeleverd + ingeleverdBijDepot - ingeslagen

const Dashboard = () => {
  const {
    title,
    informationFields,
    barchart: { noData },
  } = content.dashboard
  const { date, mode } = Route.useLoaderDeps()

  const inputDate = parseISO(date)
  const start = startOfWeek(inputDate, { weekStartsOn: 1 })
  const end = endOfWeek(inputDate, { weekStartsOn: 1 })

  const data = Route.useLoaderData().filter(({ veildatum }) =>
    isWithinInterval(parseISO(veildatum), { start, end })
  )

  return (
    <>
      <SearchFormDashboard />
      <Stack direction='column'>
        <Typography variant='h3' sx={{ mb: 4, mt: 6 }}>
          {title}
        </Typography>
        <Stack direction='row'>
          {mode === 'day' ? (
            <Typography variant='h5'>
              {format(parseISO(date), 'dd MMMM yyyy', { locale: nl })}
            </Typography>
          ) : (
            <>
              <Typography variant='h5'>
                Week {format(parseISO(date), 'II')}
              </Typography>
              <Typography variant='h5' className='book' sx={{ pl: 1 }}>
                ({getFullWeek(date)})
              </Typography>
            </>
          )}
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }}>
          <DashboardColoredTextfield
            title={informationFields.input}
            color='yellowShade'
            amount={data
              .filter(({ veildatum }) => mode === 'week' || veildatum === date)
              .map(({ ingeslagen }) => ingeslagen)
              .reduce(sum, 0)}
          />
          <DashboardColoredTextfield
            title={informationFields.delivered}
            color='royalPurpleShade'
            amount={data
              .filter(({ veildatum }) => mode === 'week' || veildatum === date)
              .map(({ afgeleverd }) => afgeleverd)
              .reduce(sum, 0)}
          />
          <DashboardColoredTextfield
            title={informationFields.deliveryDepot}
            color='hydroGreen'
            amount={data
              .filter(({ veildatum }) => mode === 'week' || veildatum === date)
              .map(({ ingeleverdBijDepot }) => ingeleverdBijDepot)
              .reduce(sum, 0)}
          />
          <DashboardColoredTextfield
            title={informationFields.difference}
            color='dutchOrange'
            amount={data
              .filter(({ veildatum }) => mode === 'week' || veildatum === date)
              .map(calculateVerschil)
              .reduce(sum, 0)}
            explanation={informationFields.differenceExplained}
          />
        </Stack>

        {data.length > 0 ? <BarChart dashboardData={data} /> : noData}
      </Stack>
    </>
  )
}

export default Dashboard
