import { endOfWeek, format, parseISO, startOfWeek } from 'date-fns'

export const getFullWeek = (dateString: string) => {
  const inputDate = parseISO(dateString)

  const startDateOfWeek = startOfWeek(inputDate, { weekStartsOn: 1 })
  const endDateOfWeek = endOfWeek(inputDate, { weekStartsOn: 1 })

  const formattedStartDate = format(startDateOfWeek, 'dd-MM-yyyy')
  const formattedEndDate = format(endDateOfWeek, 'dd-MM-yyyy')

  return `${formattedStartDate} t/m ${formattedEndDate}`
}
