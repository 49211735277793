import { FC } from 'react'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'

export const Loader: FC = () => {
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  return (
    <Backdrop
      sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
      open={isFetching > 0 || isMutating > 0}
      aria-label='backdrop'
    >
      <CircularProgress />
    </Backdrop>
  )
}
