import { format, parseISO } from 'date-fns'
import { nl } from 'date-fns/locale'

import { Box, Paper, useTheme } from '@mui/material'
import { ChartsTooltip, ResponsiveChartContainer } from '@mui/x-charts'
import { BarPlot } from '@mui/x-charts/BarChart'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight'
import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine'
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis'
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis'
import { LinePlot } from '@mui/x-charts/LineChart'

import content from '../../content'
import { DashboardTypes, FilteredData } from '../../types/Dashboard'

export const BarChart = ({
  dashboardData,
}: {
  dashboardData: DashboardTypes[]
}) => {
  const theme = useTheme()
  const { barchart } = content.dashboard

  const filteredDataResult = dashboardData
    .sort((a, b) => a.veildatum.localeCompare(b.veildatum))
    .reduce<Record<string, FilteredData>>((acc, cur) => {
      const dag = format(parseISO(cur.veildatum), 'EEEEEE dd-MM', {
        locale: nl,
      })

      if (!acc[dag]) {
        acc[dag] = {
          ingeslagen: cur.ingeslagen,
          afgeleverd: cur.afgeleverd,
          ingeleverdBijDepot: cur.ingeleverdBijDepot,
          calculatedTotals:
            cur.afgeleverd + cur.ingeleverdBijDepot - cur.ingeslagen,
        }
      } else {
        acc[dag] = {
          ingeslagen: acc[dag].ingeslagen + cur.ingeslagen,
          afgeleverd: acc[dag].afgeleverd + cur.afgeleverd,
          ingeleverdBijDepot:
            acc[dag].ingeleverdBijDepot + cur.ingeleverdBijDepot,
          calculatedTotals:
            acc[dag].calculatedTotals +
            (cur.afgeleverd + cur.ingeleverdBijDepot - cur.ingeslagen),
        }
      }

      return acc
    }, {})

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          height: 600,
          width: '100%',
        }}
        elevation={3}
      >
        <ResponsiveChartContainer
          margin={{ left: 80, right: 80 }}
          series={[
            {
              type: 'bar',
              stack: 'A',
              data: Object.values(filteredDataResult).map(
                item => item.ingeslagen
              ),
              color: theme.rfhColors.yellowShade,
              yAxisKey: 'leftAxisId',
            },
            {
              type: 'bar',
              stack: 'B',
              data: Object.values(filteredDataResult).map(
                item => item.afgeleverd
              ),
              color: theme.rfhColors.royalPurpleShade,
              yAxisKey: 'leftAxisId',
            },
            {
              type: 'bar',
              stack: 'B',
              data: Object.values(filteredDataResult).map(
                item => item.ingeleverdBijDepot
              ),
              color: theme.rfhColors.hydroGreen,
              yAxisKey: 'leftAxisId',
            },
            {
              type: 'line',
              data: Object.values(filteredDataResult).map(
                item => item.calculatedTotals
              ),
              color: theme.rfhColors.dutchOrange,
              yAxisKey: 'rightAxisId',
            },
          ]}
          xAxis={[
            {
              data: Object.keys(filteredDataResult).map(date => date),
              scaleType: 'band',
              id: 'x-axis-id',
            },
          ]}
          yAxis={[
            { id: 'leftAxisId' },
            {
              id: 'rightAxisId',
              tickNumber: 5,
            },
          ]}
          height={600}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translateX(-30px)',
            },
            [`.${axisClasses.right} .${axisClasses.label}`]: {
              transform: 'translateX(30px)',
            },
          }}
        >
          <BarPlot />
          <LinePlot />
          <ChartsAxisHighlight x='band' />
          <ChartsTooltip />
          <ChartsReferenceLine
            y={0}
            lineStyle={{ stroke: 'black' }}
            fill='white'
          />
          <ChartsXAxis
            position='bottom'
            axisId='x-axis-id'
            disableLine={true}
            disableTicks={true}
            className='custom-x-axis'
          />

          <ChartsYAxis
            label={barchart.amount}
            position='left'
            disableLine={true}
            disableTicks={true}
            axisId='leftAxisId'
          />
          <ChartsYAxis
            label={barchart.difference}
            position='right'
            disableLine={true}
            disableTicks={true}
            axisId='rightAxisId'
          />
        </ResponsiveChartContainer>
      </Paper>
    </Box>
  )
}
